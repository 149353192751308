<template>
  <ContentWrapper :links="topLinks">
    <Form inline>
      <FormItem>
        <Input v-model="queryForm.orderNo" type="text" placeholder="关联订单号" />
      </FormItem>
      <FormItem>
        <FormItem>
          <Select v-model="queryForm.status" placeholder="状态">
            <Option value="">不限</Option>
            <Option value="NEW">新建</Option>
            <Option value="READY">待支付</Option>
            <Option value="PAID">已支付</Option>
            <Option value="FAILED">失败</Option>
          </Select>
        </FormItem>
      </FormItem>
      <FormItem>
        <Select v-model="queryForm.paymentChannel" placeholder="支付渠道">
          <Option value="">不限</Option>
          <Option value="WALLET">钱包(暂不支持)</Option>
          <Option value="STRIPE">STRIPE</Option>
          <Option value="POLIPAY">POLIPAY</Option>
          <Option value="ALIPAY">ALIPAY</Option>
        </Select>
      </FormItem>
      <FormItem>
        <DatePicker
          v-model="createdAtRange"
          type="datetimerange"
          placement="bottom-end"
          placeholder="创建时间范围"
          style="width: 200px"
        ></DatePicker>
      </FormItem>
    </Form>
    <Table stripe :data="items" :columns="tableColumns" style="width: 100%" :loading="tableLoading" />
    <Pager :total="itemListCount" :current.sync="page" />
  </ContentWrapper>
</template>

<script>
import ContentWrapper from "@/components/ContentWrapper.vue"
import Pager from "@/components/Pager.vue"
import { queryReceipts } from "@/api"
import { centiToOne, badgeReceiptStatus, humanReceiptStatus } from "@/util"
import "@/components/tableUtil"

export default {
  name: "UserQueryPage",
  components: { ContentWrapper, Pager },
  data() {
    return {
      topLinks: [{ title: "交易流水", link: this.$route.path }],
      items: [],
      itemListCount: 0,
      page: 1,
      createdAtRange: ["", ""],
      queryForm: { orderNo: "", status: "", paymentChannel: "" },
      tableLoading: false,
      tableColumns: [
        { title: "流水号", key: "receiptNo", fixed: "left", width: 160, maxWidth: 160 },
        { title: "外部流水号", key: "externalReceiptNo", fixed: "left", width: 200, maxWidth: 200 },
        {
          title: "订单号",
          render: (h, p) =>
            h(
              "router-link",
              { props: { to: { name: "OrderDetailPage", query: { orderNo: p.row.orderNo } } } },
              p.row.orderNo
            ),
          width: 160,
          maxWidth: 160,
        },
        {
          title: "收款方",
          render(h, p) {
            return h("div", p.row.payee.nickname)
          },
          width: 100,
        },
        {
          title: "付款方",
          render(h, p) {
            return h("div", p.row.payer.nickname)
          },
          width: 100,
        },
        {
          title: "状态",
          render(h, p) {
            return h("div", [badgeReceiptStatus(h, p.row.status), humanReceiptStatus(p.row.status)])
          },
          width: 70,
          maxWidth: 70,
        },
        {
          title: "支付渠道",
          key: "paymentChannel",
          width: 80,
          maxWidth: 80,
        },
        {
          title: "金额",
          render(h, p) {
            return h("div", centiToOne(p.row.price))
          },
          width: 70,
          maxWidth: 70,
        },
        {
          title: "货币",
          key: "currencyUnit",
          width: 70,
          maxWidth: 70,
        },
        {
          title: "创建时间",
          render: (h, p) =>
            h("DateHolder", {
              props: { time: p.row.createdAt, defText: "-" },
            }),
          width: 100,
          maxWidth: 100,
        },
        {
          title: "支付时间",
          render: (h, p) =>
            h("DateHolder", {
              props: { time: p.row.paidAt, defText: "-" },
            }),
          width: 100,
          maxWidth: 100,
        },
        {
          title: "接口回调时间",
          render: (h, p) =>
            h("DateHolder", {
              props: { time: p.row.callbackAt, defText: "-" },
            }),
          width: 110,
          maxWidth: 110,
        },
      ],
    }
  },
  computed: {},
  watch: {
    queryForm: {
      deep: true,
      async handler() {
        await this.reload()
      },
    },
    async page() {
      await this.reload()
    },
    async createdAtRange() {
      await this.reload()
    },
  },
  async mounted() {
    await this.reload()
  },
  methods: {
    async reload() {
      try {
        this.tableLoading = true
        const q = this.queryForm
        let startTime = null,
          endTime = null
        const status = q.status === "" ? null : q.status
        const paymentChannel = q.paymentChannel === "" ? null : q.paymentChannel
        const orderNo = q.orderNo.trim() === "" ? null : q.orderNo.trim()
        if (this.createdAtRange && this.createdAtRange.length == 2 && this.createdAtRange[0] instanceof Date) {
          startTime = this.createdAtRange[0].getTime()
          endTime = this.createdAtRange[1].getTime()
        }
        const { items, count } = await queryReceipts(this.page, orderNo, startTime, endTime, paymentChannel, status)
        this.items = items
        this.itemListCount = count
      } catch (e) {
        this.$Message.error(e.message)
      } finally {
        this.tableLoading = false
      }
    },
  },
}
</script>
